<template>
  <el-container class="main-container">
    <el-row>
      <el-button @click="add">新增</el-button>
    </el-row>
    <el-card class="mt20">
      <el-row :gutter="10">
        <el-col :span="4" :offset="0">
          <el-input
            v-model="queryInfo.query"
            placeholder="请输入内容"
            clearable
            @clear="query"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="query"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <ele-select v-model="queryInfo.state"
            placeholder="状态筛选"
            :options="dataStateList"
            @change="query"></ele-select>
        </el-col>
      </el-row>
      <ele-table
        :columns-desc="columns"
        :is-show-index="true"
        :request-fn="getAppBanners"
        :right-buttons="rightButtons"
        :is-show-top-delete="false"
        :is-show-right-delete="false"
        ref="table"
      ></ele-table>
    </el-card>
    <ele-form-dialog
      v-model="formData"
      :form-desc="formDesc"
      :title="isEdit ? '编辑横幅' : '添加横幅'"
      :request-fn="handleSubmit"
      :visible.sync="dialogVisible"
      @closed="handleClosed"
    ></ele-form-dialog>
  </el-container>
</template>

<script>
import { bannerTypeList, tableDataStateList } from "@/assets/js/constant.js";
export default {
  data() {
    let that = this
    return {
      queryInfo: {
        query: "",
        state: "",
      },
      dataStateList: tableDataStateList,
      appImageOptions: [],
      appTagOptions: [],
      appClassifyOptions: [],
      columns: {
        name: {
          text: "横幅名称",
        },
        type: {
          text: "横幅类型",
          type: "status",
          options: bannerTypeList,
        },
        coverUrl: {
          text: "首页图片",
          type: 'image'
        },
        target: {
          text: "目标",
        },
        dataState: {
          text: '数据状态',
          type: 'status',
          options: tableDataStateList
        }
      },
      rightButtons: [
        {
          text: "编辑",
          attrs: {
            type: "primary",
          },
          click: (id, index, data) => {
            this.edit(data);
          },
        },
        {
          text: "删除",
          attrs: {
            type: "danger",
          },
          click: (id, index, data) => {
            this.remove(data);
          },
        },
        {
          text: '恢复',
          attrs: {
            type: 'success'
          },
          click: (id, index, data) => {
            this.restore(data)
          }
        }
      ],
      isEdit: false,
      dialogVisible: false,
      formData: {},
      formDesc: {
        name: {
          label: "横幅名称",
          type: "input",
          required: true,
        },
        type: {
          label: "横幅类型",
          type: "select",
          options: bannerTypeList,
          required: true,
        },
        coverType: {
          label: '首页图片选择',
          type: 'radio',
          default: 1,
          options: [
            { text: '在线上传', value: 1 },
            { text: '选择已有', value: 0 }
          ]
        },
        coverUrl: {
          label: "首页图片",
          type: "image-uploader",
          vif(data) {
            return data.coverType === 1
          },
          attrs: {
            fileType: ['png', 'gif', 'jpg', 'jpeg'],
            drag: true,
            headers: {
              Authorization: 'Bearer ' + that.$store.state.token
            },
            responseFn(response) {
              return response.data.url
            }
          },
          required: true,
        },
        imageUrl: {
          label: '选择首页图片',
          type: "image-select",
          vif(data) {
            return data.coverType === 0
          },
          required: true
        },
        imageTarget: {
          label: "目标",
          type: "image-select",
          vif(data) {
            return data.type === 0
          },
          required: true
        },
        linkTarget: {
          label: '目标',
          type: 'input',
          vif(data) {
            return data.type === 1 || data.type == 4
          }
        },
        classifyTarget: {
          label: '目标',
          type: 'select',
          options: async() => {
            return await this.appClassifyOptions
          },
          vif(data) {
            return data.type === 2
          }
        },
        tagTarget: {
          label: '目标',
          type: 'select',
          options: async() => {
            return await this.appTagOptions
          },
          vif(data) {
            return data.type === 3
          }
        },
        keepTime: {
          label: "保持时间",
          type: "input",
          attrs: {
            type: "number",
            step: "0.001",
            min: "0",
          },
          required: true,
        },
      },
    };
  },
  created() {
    this.getOptions()
  },
  methods: {
    query() {
      this.$refs.table.getData();
    },
    async getOptions() {
      const { data: res } = await this.$api.getOptions()
      if(res.code > 0) {
        return this.$notify.error({
          title: '错误',
          message: res.message
        })
      }
      this.appImageOptions = res.data.appImages
      this.appTagOptions = res.data.appTags
      this.appClassifyOptions = res.data.appClassifies
    },
    async getAppBanners(params) {
      const { data: res } = await this.$api.getAppBanners(
        Object.assign(params, this.queryInfo)
      );
      if (res.code > 0) {
        return this.$notify.error({
          title: '错误',
          message: res.message
        })
      }
      return res.data;
    },
    add() {
      this.isEdit = false;
      this.dialogVisible = true;
    },
    edit(data) {
      this.isEdit = true;
      this.formData = data;
      this.dialogVisible = true;
    },
    selectImage() {

    },
    async remove(data) {
      const confirm = await this.$confirm(
        "此操作将删除该banner, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true
        }
      );
      if (confirm === "confirm") {
        const { data: res } = await this.$api.removeAppBanner(data.id, false);
        if (res.code > 0) {
          return this.$notify.error({
            title: '错误',
            message: res.message
          })
        }
        this.$notify.success({
          title: '成功',
          message: res.message
        })
        this.query();
      }
    },
    async restore(data) {
      const confirm = await this.$confirm(
        "此操作将恢复该banner, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true
        }
      );
      if (confirm === "confirm") {
        const { data: res } = await this.$api.removeAppBanner(data.id, true);
        if (res.code > 0) {
          return this.$notify.error({
            title: '错误',
            message: res.message
          })
        }
        this.$notify.success({
          title: '成功',
          message: res.message
        })
        this.query();
      }
    },
    async handleSubmit(data) {
      const { data: res } = await this.$api.editAppBanner(data);
      if (res.code > 0) {
        return this.$notify.error({
          title: '错误',
          message: res.message
        })
      }
      this.dialogVisible = false;
      this.query();
      this.$notify.success({
        title: '成功',
        message: res.message
      })
    },
    handleClosed() {
      this.formData = {};
    },
  },
};
</script>